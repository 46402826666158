import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./PageWrapper.css";

const PageWrapper = ({ titleKey, contentKey, children }) => {
  const { t, i18n } = useTranslation();

  // Toggle between English and Dutch
  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "nl" : "en";
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div className="PageWrapper">
      <div className="div">
        <div className="overlap-8">
          <img className="frame-31" alt="Frame" src="/img/desktop/frame-1073714006.png" />
          <img className="blur-2" alt="Blur" src="/img/desktop/blur.svg" />
        </div>
        <div className="container-22">
          <div className="heading-2">
            <div className="title">{t(titleKey)}</div>
          </div>
          {/* Render either the contentKey or children */}
          {contentKey ? <p className="content-section">{t(contentKey)}</p> : children}
        </div>
        <div className="frame-61">
          <div className="container-30">
            <div className="list-wrapper">
              <div className="list">
                <div className="div-wrapper-2">
                  <div className="text-wrapper-51">
                    <Link to="/privacy-policy" className="privacy-policy-link">
                      {t("Privacy Policy")}
                    </Link>
                  </div>
                </div>

                <div className="div-wrapper-2">
                  <div className="text-wrapper-51">
                    <Link to="/terms-and-conditions" className="terms-and-conditions-link">
                      {t("Terms and Conditions")}
                    </Link>
                  </div>
                </div>

                <div className="div-wrapper-2">
                  <div className="text-wrapper-51">
                    <Link to="/blogs" className="blogs-link">
                      {t("Blogs")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-wrapper-2">
              <p className="text-wrapper-51">
                {t("summie B.V. All rights reserved.")}
              </p>
            </div>
          </div>
        </div>
        
        <div className="follow-5">
          <a
          href="https://summie.co/download?utm_source=website&utm_medium=button&utm_campaign=download_campaign"
          target="_blank"
          rel="noopener noreferrer"
          >
            <div className="border" />
            <img className="SVG" alt="Svg" src="/img/desktop/svg-3.svg" />
            <div className="text-wrapper-6">{t("Download")}</div>
          </a>
        </div>

        <div className="language-toggle-mobile">
          <button onClick={toggleLanguage}>
            {i18n.language === "en" ? "nl" : "en"}
          </button>
        </div>
        <a href="/">
          <img
            className="group-21"
            alt="Group"
            src="/img/desktop/group-1073714096.svg"
          />
        </a>
      </div>
    </div>
  );
};

export default PageWrapper;