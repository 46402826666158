import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const DownloadRedirect = () => {
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const utmParams = searchParams.toString(); // Preserve UTM parameters

        // UTM-waarden ophalen en naar Google Tag Manager (GTM) sturen
        const utm_source = searchParams.get("utm_source") || "unknown";
        const utm_medium = searchParams.get("utm_medium") || "unknown";
        const utm_campaign = searchParams.get("utm_campaign") || "unknown";
        const utm_content = searchParams.get("utm_content") || "unknown";

        // Stuur event naar GTM
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "download",
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content
        });

        // 500ms wachten zodat tracking kan plaatsvinden
        setTimeout(() => {
            const appStoreURL = `https://apps.apple.com/app/summie/id6737628622?${utmParams}`;
            window.location.href = appStoreURL;
        }, 500);
    }, [location]);

    return <p>Redirecting to the App Store...</p>;
};

export default DownloadRedirect;