import React from "react";
import { Helmet } from "react-helmet";
import PageWrapper from "../PageWrapper";

const EmpoweringGenZ = () => {
    return (
        <PageWrapper titleKey="Empowering Gen-Z and Millennials">
            <Helmet>
                <title>Empowering Gen-Z and Millennials | summie Blogs</title>
                <meta
                    name="description"
                    content="Discover why financial literacy is essential for Gen-Z and Millennials, and how summie empowers young entrepreneurs to succeed."
                />
                <meta
                    name="keywords"
                    content="financial literacy, Gen-Z, Millennials, entrepreneurship, financial tools, summie"
                />
                <meta name="author" content="Nathalie Bais" />
            </Helmet>
            <div className="content-section">
                <p>
                    I have seen firsthand how younger generations are stepping into entrepreneurship earlier than ever before.
                    Freelancing, building a personal brand, or launching a startup all require managing your finances well,
                    as it can make or break your success.
                </p>
                <h2>Why financial literacy matters</h2>
                <p>
                    Financial literacy goes beyond knowing how to save or invest. It's about managing cash flow, tracking expenses,
                    and staying on top of VAT and tax obligations. Too many young entrepreneurs dive into business without these
                    essential skills, leading to stress and costly mistakes.
                </p>
                <h2>Young entrepreneurs need better tools</h2>
                <p>
                    At summie, we believe financial management should be easy and accessible for everyone. Our mission is to simplify
                    financial literacy and organization.
                </p>
                <ul>
                    <li>Simplified financial admin: Easily scan, upload, and link receipts and invoices to transactions.</li>
                    <li>Clear financial overviews: Gain insights into VAT, expenses, and income effortlessly.</li>
                    <li>Time-saving automation: Export admin directly to your accountant or favorite tools, minimizing manual tasks.</li>
                </ul>
                <p>
                    We don’t just offer an admin tool—we are giving young entrepreneurs the power to make informed decisions, avoid
                    financial pitfalls, and build sustainable businesses.
                </p>
                <h2>Breaking the cycle of financial confusion</h2>
                <p>
                    Too often, financial education is overlooked in school, leaving many to figure it out through trial and error.
                    summie is changing that by providing a simple platform that teaches financial organization as you manage your business.
                </p>
                <h2>Join the movement</h2>
                <p>
                    We are on a mission to make financial clarity accessible for everyone—especially the new wave of young entrepreneurs.
                    By simplifying financial admin, summie empowers you to focus on your passions, not paperwork.
                </p>
                <br></br>
                <p>
                    <span>
                        <strong><u><a href="https://summie.co/download?utm_source=website&utm_medium=button&utm_campaign=download_campaign" target="_blank" rel="noopener noreferrer">
                            Start your 1-week free trial today
                        </a></u></strong>
                    </span>
                </p>
                <p>
                    After that, it’s just €5.99/month—a small price for peace of mind.
                </p>
                <br></br>
                <p>
                    Financial success starts with clarity. Let summie be your partner on the journey.
                </p>
                <p>
                    <strong>Nathalie Bais</strong><br />
                    CEO & Co-founder
                </p>
            </div>
        </PageWrapper>
    );
};

export default EmpoweringGenZ;