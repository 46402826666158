import React, { useState } from "react";
import { Link } from 'react-router-dom'; 
import { useTranslation } from "react-i18next";
import "./phone.css";

export const Iphone = () => {
  const { t, i18n } = useTranslation();

  // Toggle between English and Dutch
  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "nl" : "en";
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div className="iphone">
      <div className="div-3">
        <div className="overlap">
          <img className="group" alt="Group" src="/img/mobile/group-1073714128.png" />

          <div className="rectangle-4" />

          <img className="blur" alt="Blur" src="/img/mobile/blur-2.svg" />

          <div className="iphone-pro">
            <img className="silver" alt="Silver" src={t("overview-mobile")}/>
          </div>

          <div className="frame-37">
            <div className="container">
              <div className="overlap-5">
                <div className="overlap-group-5">
                  <div className="receipt-capture-wrapper">
                    <div className="receipt-capture">
                      {t("Receipt capture")}
                    </div>
                  </div>

                  <div className="container-2">
                    <p className="text-wrapper-22">{t("all in one secure place")}</p>
                  </div>
                </div>

                <img className="frame-38" alt="Frame" src="/img/mobile/frame-32.svg" />
              </div>
            </div>

            <div className="container">
              <div className="overlap-5">
                <img className="frame-39" alt="Frame" src="/img/mobile/frame-33.svg" />

                <div className="overlap-group-5">
                  <div className="organize-track-wrapper">
                    <div className="text-wrapper-23">{t("Organize")}</div>
                  </div>

                  <div className="container-3">
                    <div className="text-wrapper-24">
                      {t("easy expense management")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="container-4">
                <div className="text-wrapper-23">{t("Intuitive design")}</div>
              </div>

              <div className="container-5">
                <div className="text-wrapper-24">{t("and simple to navigate")}</div>
              </div>

              <img className="frame-40" alt="Frame" src="/img/mobile/frame-34.svg" />
            </div>

            <div className="container">
              <div className="overlap-5">
                <div className="overlap-group-5">
                  <div className="container-6">
                    <div className="text-wrapper-23">{t("Export your files")}</div>
                  </div>

                  <div className="container-7">
                    <div className="text-wrapper-24">{t("for easy sharing")}</div>
                  </div>
                </div>

                <img className="frame-41" alt="Frame" src="/img/mobile/frame-35.svg" />
              </div>
            </div>

            <div className="container">
              <div className="container-8">
                <div className="text-wrapper-25">{t("Data security focus")}</div>
              </div>

              <div className="container-9">
                <div className="text-wrapper-26">
                  {t("your privacy, fully protected")}
                </div>
              </div>

              <img className="frame-42" alt="Frame" src="/img/mobile/frame-36.svg" />
            </div>
          </div>
        </div>

        <div className="overlap-6">
          <img
            className="frame-43"
            alt="Frame"
            src="/img/mobile/frame-1073714006.png"
          />

          <img className="blur-2" alt="Blur" src="/img/mobile/blur-1-2.svg" />

          <img
            className="group-2"
            alt="Group"
            src="/img/mobile/group-1073714096.svg"
          />
          <div className="follow-99">
            <a
            href="https://summie.co/download?utm_source=website&utm_medium=button&utm_campaign=download_campaign"
            target="_blank"
            rel="noopener noreferrer"
            >
              <div className="border-99" />
              <img className="apple-icon-99" alt="Svg" src="/img/mobile/apple-icon-99.svg" />
              <div className="text-wrapper-99">{t("Download")}</div>
            </a>
          </div>

        <div className="language-toggle-mobile">
          <button onClick={toggleLanguage}>
            {i18n.language === "en" ? "nl" : "en"}
          </button>
        </div>

          <div className="container-10">
            <p className="save-time-and-money">
              {t("Save time and money")}
              <br />
              {t("with summie.")}
              <sup>®</sup>
            </p>

            <p className="text-wrapper-27">{t("We simplify")}</p>

            <div className="follow">
              <a
              href="https://summie.co/download?utm_source=website&utm_medium=button&utm_campaign=download_campaign"
              target="_blank"
              rel="noopener noreferrer"
              >
                <div className="border" />
                <img className="SVG" alt="Svg" src="/img/mobile/svg-2.svg" />
                <div className="text-wrapper-28">{t("Download")}</div>
              </a>
            </div>
          </div>
        </div>

        <video 
          autoPlay
          muted
          playsInline
            className="iphone-pro-mockup"
            alt="Iphone pro mockup"
            >
            <source src="/img/desktop/LandingPageAnimation.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

        <div className="frame-44">
          <div className="container-11">
            <div className="overlay-shadow">
              <div className="container-12">
                <div className="text-wrapper-29">{t("Our motto")}</div>
              </div>

              <img
                className="mask-group"
                alt="Mask group"
                src="/img/mobile/mask-group-1-2.svg"
              />
            </div>

            <div className="text-wrapper-30">{t("Why summie")}</div>

            <p className="text-wrapper-27">
              {t("In today’s fast-paced world")} <br />
              {t("We are here to")}
            </p>
          </div>

          <div className="container-13">
            <div className="frame-45">
              <div className="overlap-7">
                <div className="background">
                  <div className="frame-46">
                    <div className="text-wrapper-31">{t("Save time")}</div>

                    <p className="text-wrapper-32">{t("No more paper")}</p>
                  </div>

                  <img
                    className="mask-group-2"
                    alt="Mask group"
                    src="/img/mobile/mask-group-2-2.svg"
                  />
                </div>

                <div className="archive-2">
                  <img
                    className="frame-47"
                    alt="Frame"
                    src={t("library-mobile")}
                  />
                </div>
              </div>
            </div>

            <div className="frame-68">
              <div className="overlap-8">
                <div className="background">
                  <div className="frame-46">
                    <div className="text-wrapper-31">{t("Save money")}</div>

                    <p className="text-wrapper-32">{t("Manage your financial")}</p>
                  </div>

                  <img
                    className="mask-group-3"
                    alt="Mask group"
                    src="/img/mobile/mask-group-3-2.svg"
                  />
                </div>

                <div className="archive-3">
                 <img
                  className="frame-72"
                  alt="Frame"
                  src={t("widget-mobile")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-14">
          <div className="overlay-shadow">
            <div className="container-12">
              <div className="text-wrapper-29">{t("Features")}</div>
            </div>

            <img
              className="mask-group-4"
              alt="Mask group"
              src="/img/mobile/mask-group-4.svg"
            />
          </div>

          <p className="text-wrapper-39">{t("The app that works for you")}</p>

          <p className="text-wrapper-40">{t("With summie")}</p>
        </div>

        <div className="overlap-9">
          <p className="text-wrapper-41">{t("summie started with a simple idea")}{t("of the paper clutter")}</p>

          <p className="text-wrapper-42">{t("summie started with a simple idea")}</p>
        </div>

        <div className="background-2">
          <div className="container-wrapper">
            <div className="container-15">
              <div className="overlay-shadow">
                <div className="container-12">
                  <div className="text-wrapper-29">{t("result")}</div>
                </div>

                <img
                  className="mask-group-5"
                  alt="Mask group"
                  src="/img/mobile/mask-group-2.svg"
                />
              </div>

              <div className="container-16">
                <p className="text-wrapper-43">{t("combined_skills")}</p>

                <p className="text-wrapper-44">{t("dream")}</p>
              </div>
            </div>
          </div>

          <div className="group-wrapper">
            <div className="group-3">
              <div className="overlap-10">
                <div className="group-4">
                  <div className="overlap-group-7">
                    <div className="text-wrapper-45">Modern Finance,</div>

                    <img
                      className="made-simple"
                      alt="Made simple"
                      src="/img/mobile/made-simple.svg"
                    />
                  </div>
                </div>

                <img className="group-5" alt="Group" src="/img/mobile/group.png" />

                <img
                  className="group-6"
                  alt="Group"
                  src="/img/mobile/group-1-2x.png"
                />

                <img className="group-7" alt="Group" src="/img/mobile/group-2.png" />

                <img className="group-8" alt="Group" src="/img/mobile/group-3.png" />

                <img
                  className="group-9"
                  alt="Group"
                  src="/img/mobile/group-4-2x.png"
                />

                <img
                  className="group-10"
                  alt="Group"
                  src="/img/mobile/group-5-2x.png"
                />

                <img
                  className="group-11"
                  alt="Group"
                  src="/img/mobile/group-6-2x.png"
                />

                <img
                  className="group-12"
                  alt="Group"
                  src="/img/mobile/group-7-2x.png"
                />

                <img
                  className="group-13"
                  alt="Group"
                  src="/img/mobile/group-8-2x.png"
                />

                <img
                  className="group-14"
                  alt="Group"
                  src="/img/mobile/group-9-2x.png"
                />

                <img
                  className="group-15"
                  alt="Group"
                  src="/img/mobile/group-12-2x.png"
                />

                <img
                  className="group-16"
                  alt="Group"
                  src="/img/mobile/group-13-2x.png"
                />

                <img
                  className="group-17"
                  alt="Group"
                  src="/img/mobile/group-14-2.png"
                />

                <img
                  className="clip-path-group"
                  alt="Clip path group"
                  src="/img/mobile/clip-path-group.png"
                />

                <img
                  className="group-18"
                  alt="Group"
                  src="/img/mobile/group-1073714129.png"
                />

                <div className="group-19" />

                <div className="group-20" />

                <div className="group-21" />

                <div className="text-wrapper-46">+25%</div>
              </div>

              <div className="overlap-11">
                <img
                  className="group-22"
                  alt="Group"
                  src="/img/mobile/frame-1073714128-1.png"
                />

                <img
                  className="group-23"
                  alt="Group"
                  src="/img/mobile/frame-1073714128-1.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-17">
          <div className="icon">
            <img className="union" alt="Union" src="/img/mobile/union.svg" />
          </div>

          <p className="upgrade-to-the-new">{t("upgrade_message")}</p>

          <div className="follow">
            <a
            href="https://summie.co/download?utm_source=website&utm_medium=button&utm_campaign=download_campaign"
            target="_blank"
            rel="noopener noreferrer"
            >
              <div className="border" />
              <img className="SVG" alt="Svg" src="/img/mobile/svg-1.svg" />
              <div className="text-wrapper-28">{t("Download")}</div>
            </a>
          </div>
        </div>

        <div className="follow-2">
          <a href="https://www.instagram.com/summie.app?igsh=dnAwdTg1cGhhM2g3" target="_blank" rel="noopener noreferrer">
           <img className="image" alt="Image" src="/img/mobile/image-7.png" />
          </a>
          <div className="text-wrapper-47">
           <a href="https://www.instagram.com/summie.app?igsh=dnAwdTg1cGhhM2g3" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
        </div>

        <div className="frame-84">
          <div className="div-wrapper-2">
            <div className="text-wrapper-48">
               <Link to="/privacy-policy" className="privacy-policy-link">
                {t("Privacy Policy")}
              </Link>
            </div>
          </div>

          <div className="div-wrapper-2">
            <div className="text-wrapper-48">
              <Link to="/terms-and-conditions" className="terms-and-conditions-link">
                {t("Terms and Conditions")}
              </Link>
            </div>
          </div>

          <div className="div-wrapper-2">
            <div className="text-wrapper-48">
                <Link to="/blogs" className="blogs-link">
                {t("Blogs")}
               </Link>
            </div>
          </div>

          <div className="div-wrapper-2">
            <p className="text-wrapper-48">
              {t("summie B.V. All rights reserved.")}
            </p>
          </div>
        </div>

        <div className="text-wrapper-49">
          <a href="mailto:hello@summie.co">hello@summie.co</a>
          </div>

        <img className="logo" alt="Logo" src="/img/mobile/logo-2.svg" />
      </div>
    </div>
  );
};
