import React from "react";
import PageWrapper from "./PageWrapper";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper titleKey="Privacy-policy">
      <div className="content-section">
        <p>{t("privacy.lastUpdated")}</p>

        <p>{t("privacy.introduction")}</p>

        <h2>{t("privacy.whatDataTitle")}</h2>
        <p>{t("privacy.whatDataDescription")}</p>
        <ul>
          <li>
            <strong>{t("privacy.data.registration.title")}</strong>
            <ul>
              <li>{t("privacy.data.registration.name")}</li>
              <li>{t("privacy.data.registration.email")}</li>
              <li>{t("privacy.data.registration.userID")}</li>
              <li>{t("privacy.data.registration.password")}</li>
            </ul>
          </li>
          <li>
            <strong>{t("privacy.data.payment.title")}</strong>
            <ul>
              <li>{t("privacy.data.payment.details")}</li>
            </ul>
          </li>
          <li>
            <strong>{t("privacy.data.technical.title")}</strong>
            <ul>
              <li>{t("privacy.data.technical.details")}</li>
            </ul>
          </li>
          <li>
            <strong>{t("privacy.data.analytics.title")}</strong>
            <ul>
              <li>{t("privacy.data.analytics.details")}</li>
            </ul>
          </li>
          <li>
            <strong>{t("privacy.data.advertising.title")}</strong>
            <ul>
              <li>{t("privacy.data.advertising.details")}</li>
            </ul>
          </li>
        </ul>

        <h2>{t("privacy.whyDataTitle")}</h2>
        <p>{t("privacy.whyDataDescription")}</p>
        <ul>
          <li>{t("privacy.whyData.createAccount")}</li>
          <li>{t("privacy.whyData.processSubscriptions")}</li>
          <li>{t("privacy.whyData.transactions")}</li>
          <li>{t("privacy.whyData.userExperience")}</li>
          <li>{t("privacy.whyData.advertisingEffects")}</li>
          <li>{t("privacy.whyData.legalObligations")}</li>
        </ul>

        <h2>{t("privacy.howDataTitle")}</h2>
        <p>{t("privacy.howDataDescription")}</p>
        <ul>
          <li>{t("privacy.howData.createAccount")}</li>
          <li>{t("privacy.howData.connectBank")}</li>
          <li>{t("privacy.howData.technicalData")}</li>
          <li>{t("privacy.howData.analytics")}</li>
          <li>{t("privacy.howData.advertising")}</li>
        </ul>

        <h2>{t("privacy.shareDataTitle")}</h2>
        <p>{t("privacy.shareDataDescription")}</p>
        <ul>
          <li>{t("privacy.shareData.gocardless")}</li>
          <li>{t("privacy.shareData.firebase")}</li>
          <li>{t("privacy.shareData.appleGoogle")}</li>
          <li>{t("privacy.shareData.revenuecat")}</li>
          <li>{t("privacy.shareData.microsoftClarity")}</li>
          <li>{t("privacy.shareData.facebook")}</li>
          <li>{t("privacy.shareData.visionparser")}</li>
          <li>{t("privacy.shareData.devTeams")}</li>
        </ul>

        <h2>{t("privacy.yourRightsTitle")}</h2>
        <p>{t("privacy.yourRightsDescription")}</p>
        <ul>
          <li>{t("privacy.yourRights.access")}</li>
          <li>{t("privacy.yourRights.correct")}</li>
          <li>{t("privacy.yourRights.delete")}</li>
          <li>{t("privacy.yourRights.restrict")}</li>
          <li>{t("privacy.yourRights.withdraw")}</li>
        </ul>

        <h2>{t("privacy.securityTitle")}</h2>
        <p>{t("privacy.securityDescription")}</p>
        <ul>
          <li>{t("privacy.security.ssl")}</li>
          <li>{t("privacy.security.encryption")}</li>
          <li>{t("privacy.security.authorizedPersonnel")}</li>
        </ul>

        <h2>{t("privacy.contactTitle")}</h2>
          <p>
            {t("privacy.contactDetails")}{" "}
            <a href="mailto:hello@summie.co" className="email-link">
            hello@summie.co
            </a>
          </p>
      </div>
    </PageWrapper>
  );
};

export default PrivacyPolicy;